<template>
    <div class="page-help" :class="isScrolled?'active':'unactive'">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :left-text="$t('帮助中心')" left-arrow @click-left="onClickLeft"/>
        <div class="wrapper">
            <div class="bs-panel help-panel">
                <div class="help-tt">{{$t('常见问题')}}</div>
                <van-collapse v-model="activeNames" class="help-collapse">
                    <van-collapse-item v-for="(item, index) in list" :name="index" :key="index">
                        <template #title>
                            <div class="h-title" style="padding: 2px 5px">
                                <span class="tag">Q</span>{{item.title}}
                            </div>
                        </template>
                        <div class="h-content">
                            <div class="flex_bd" v-html="item.content">
                            </div>
                        </div>
                    </van-collapse-item>
                </van-collapse>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                activeNames: [0],
                list: [],
                isScrolled: false
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
            getData() {
                this.$axios
                    .get(this.$api_url.get_help)
                    .then((res) => {
                        this.list = res.data.qa_list;
                    });
            },
            handleScroll() {
                if (window.pageYOffset >= 100) {
                    this.isScrolled = true
                } else {
                    this.isScrolled = false
                }
            }
        },
        mounted() {
            this.getData();
            window.addEventListener('scroll', this.handleScroll)
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleScroll)
        },
    }
</script>
<style scoped>
    .page{
        background: unset;
    }
    .page-help{
        padding-top: 100px;
        padding-right: 20px;
        padding-left: 20px;
        min-height: 80vh;
        background: url(../../assets/pictures/helpbanner.jpg) center top no-repeat;
        background-size: contain;
    }
    .page-help.unactive ::v-deep .van-nav-bar{
        background-color: transparent!important;
    }
    .help-panel{
        padding: 20px;
    }
    .help-tt{
        margin-bottom: 10px;
        font-size: 18px;
        text-align: center;
    }
    .help-panel ::v-deep .van-cell{
        background-color: transparent;
        padding: 10px 0;
        color: #ffffff;
    }
    .help-panel ::v-deep .van-collapse-item__content{
        background-color: transparent;
        color: #8c93b4;
    }
    .help-panel ::v-deep .van-collapse-item__content{
        opacity: .8;
    }
    .help-panel ::v-deep .van-collapse::after{
        display: none;
    }
    .help-panel ::v-deep .van-collapse-item__title::after{
        display: none;
    }
    .help-panel ::v-deep .van-collapse-item--border::after{
        display: none;
    }
    .h-title .tag{
        width: 30px;
        height: 30px;
        text-align: center;
        margin-right: 10px;
        border-radius: 8px;
        background-color: #3661ed;
        color: #ffffff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
    }



</style>